const mediaMinSm = window.matchMedia('(min-width: 576px)');
const mediaMinMd = window.matchMedia('(min-width: 768px)');
const mediaMinLg = window.matchMedia('(min-width: 992px)');
const mediaMinXl = window.matchMedia('(min-width: 1200px)');
const mediaMinXxl = window.matchMedia('(min-width: 1400px)');

const mediaMaxSm = window.matchMedia('(max-width: 576px)');
const mediaMaxMd = window.matchMedia('(max-width: 768px)');
const mediaMaxLg = window.matchMedia('(max-width: 992px)');
const mediaMaxXl = window.matchMedia('(max-width: 1200px)');
const mediaMaxXxl = window.matchMedia('(max-width: 1400px)');

const headerHp = document.querySelector("header.header-hp");

const getSingle = (id) => {
	return document.querySelector(id);
};

const getMultiple = (el) => {
	return document.querySelectorAll(el);
};

const html = document.querySelector("html");
const body = document.querySelector("body");
const header = document.querySelector("header");
const footer = document.querySelector("footer");

const sliderArrivals = new Swiper(
	".new-arrivals-slider .new-arrivals-slider-container",
	{
		slidesPerView: 1.6,
		spaceBetween: 16,
		// Navigation arrows
		navigation: {
			nextEl: ".new-arrivals-slider .arrow-next",
			prevEl: ".new-arrivals-slider .arrow-prev",
			disabledClass: "arrow-disabled",
		},
	}
);

const sliderReview = new Swiper(".review-slider .review-slider-container", {
	// Navigation arrows
	navigation: {
		nextEl: ".review-slider .arrow-next",
		prevEl: ".review-slider .arrow-prev",
		disabledClass: "arrow-disabled",
	},
});



function bottleInfo() {
	const sheetButtons = getMultiple(".bottle-info");
	const sheetWrappers = getMultiple(".sheet-wrapper");

	const sheetTrigger = document.querySelectorAll("[data-sheet-trigger]");
	const sheetTargets = document.querySelectorAll("[data-sheet-target]");

	function createSheetBackdrop() {
		const div = document.createElement("div");
		div.classList.add('sheet-backdrop');

		body.appendChild(div);

		setTimeout(() => {
			div.classList.add('open')
		}, 200)
	}

	function removeSheetBackdrop() {
		const div = document.querySelector('.sheet-backdrop')

		div.classList.remove('open')

		setTimeout(() => {
			div.remove()
		}, 200)

		html.style.overflow = null;
	}

	function checkSheetOpen(target) {
		if (!target.classList.contains("sheet-open")) {
			target.classList.add("sheet-open");
			html.style.overflow = "hidden";

			createSheetBackdrop();
		}
	}

	sheetTrigger.forEach(trigger => {
		trigger.addEventListener('click', (trigger) => {
			let triggertValue = trigger.currentTarget.getAttribute("data-sheet-trigger");

			sheetTargets.forEach(sheetTarget => {
				let sheetTargetValue = sheetTarget.getAttribute("data-sheet-target");

				if (triggertValue === sheetTargetValue) {
					checkSheetOpen(sheetTarget);
				}
			});


		})
	});

	// Close Sheet
	const sheetCloseBtns = document.querySelectorAll(".sheet-close-btn");

	sheetCloseBtns.forEach(closeBtn => {
		closeBtn.addEventListener('click', () => {
			let closeBtnParent = closeBtn.closest(".sheet-wrapper");

			if (closeBtnParent.classList.contains('sheet-open')) {
				closeBtnParent.classList.remove('sheet-open');

				removeSheetBackdrop();
			}
		})
	});
}

function customBoxActive() {
	const customBox = getMultiple(".custom-box");

	customBox.forEach((el) => {
		const elButton = el.querySelector('[data-bs-toggle="collapse"]');
		elButton.addEventListener("click", () => {
			customBox.forEach((el) => {
				el.classList.remove("active");
			});

			if (elButton.getAttribute("aria-expanded") === "true") {
				el.classList.add("active");
			} else {
				el.classList.remove("active");
			}
		});
	});
}

function boxCartHeight() {
	let boxCart = getSingle(".box-cart");

	if (boxCart) {
		if (mediaMaxLg.matches) {
			boxCart = boxCart.offsetHeight + 32;
			body.style.paddingBottom = `${boxCart}px`;
		} else {
			body.style.paddingBottom = null
		}
	}
}

function collapseHome() {
	const myCollapsible = getSingle(".accordion.accordion-space");
	const accordion = getMultiple(".accordion.accordion-space .accordion-item");

	if (myCollapsible) {
		myCollapsible.addEventListener("show.bs.collapse", (event) => {
			event.target.closest(".accordion-item").classList.add("active");
		});

		myCollapsible.addEventListener("hide.bs.collapse", (event) => {
			accordion.forEach((el) => {
				el.classList.remove("active");
			});
		});

		myCollapsible.addEventListener("shown.bs.collapse", (event) => {
			event.target.closest(".accordion-item").classList.add("active");
		});
	}
}

function headerHpScroll() {
	if (headerHp) {
		if (window.scrollY > 90) {
			headerHp.classList.add("scrolled");
		} else {
			headerHp.classList.remove("scrolled");
		}
	}
}

function checkCategory() {
	const categoryItem = document.querySelectorAll(".category-select-item");

	if (categoryItem) {
		categoryItem.forEach((el) => {
			let categoryInput = el.querySelector(".category-select-input");
			categoryInput.addEventListener("click", () => {
				if (el.classList.contains("checked")) {
					el
						.classList
						.remove("checked");
				} else {
					el
						.classList
						.add("checked");
				}
			});
		});
	}
}

function burgerAnimation() {
	let burger = getSingle('.burger-menu');

	if (burger) {
		burger.addEventListener('click', () => {
			burger.classList.toggle('open');
		})
	}
}

function headerHeight() {
	if (header) {
		// min 9992
		if (mediaMinLg.matches) {
			body.classList.remove('header-mobile')
			body.classList.add('header-desktop')
		} else {
			body.classList.remove('header-desktop')
			body.classList.add('header-mobile')
		}
	}
}

function burgerClick() {
	if (header) {
		const burger = header.querySelector('.hamburger');

		burger.addEventListener('click', (e) => {
			if (body.classList.contains('header-open')) {
				body.classList.remove('header-open');
				burger.classList.remove('is-active');
			} else {
				body.classList.add('header-open');
				burger.classList.add('is-active');
			}
		})
	}
}

function cartStickyTop() {
	var sticky = new Sticky('.sticky-top-desktop', {
		stickyFor: 991,
		marginTop: 32
	});
}

const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl));

window.addEventListener("DOMContentLoaded", (event) => {
	bottleInfo();
	boxCartHeight();
	customBoxActive();
	collapseHome();
	headerHpScroll();
	checkCategory();
	burgerAnimation();
	// headerHeight();
	burgerClick();
	cartStickyTop();
});

window.addEventListener("resize", (event) => {
	boxCartHeight();
	// headerHeight();
});

window.addEventListener("scroll", (event) => {
	headerHpScroll();
});
